/* eslint-disable */
import Cookies from 'js-cookie';

// Wrapper around js-cookie to share same Api as Storage (LocalStorage, SessionStorage)
export class Cookie {
  constructor() {
    this.storage = Cookies;
    this.storage.defaults.secure = (location.protocol.indexOf('https') > -1);
    this.available = this.isAvailable();
  }

  isAvailable() {
    const testKey = '__cookie_test__';
    let canSet = false;

    if (this.available !== undefined) {
      return this.available;
    }

    try {
      // Create cookie
      document.cookie = `${testKey}=1`;
      canSet = document.cookie.indexOf(`${testKey}=`) !== -1;
      // Delete cookie
      document.cookie = `${testKey}=1; expires=Thu, 01-Jan-1970 00:00:01 GMT`;
    } catch (err) {
      canSet = false;
    }

    return canSet;
  }

  set(key, value, options = {}) {
    if (this.available) {
      return this.storage.set(key, value, options);
    }
  }

  get(key, defaultValue = undefined, raw = false) {
    if (this.available) {
      let value = this.storage.get(key);

      if (!raw && /^[{[]/.test(value)) {
        try {
          value = JSON.parse(value);
        } catch (err) {
          // return normal
        }
      }

      return (value === undefined) ? defaultValue : value;
    }

    return defaultValue;
  }

  remove(key, domain = '') {
    if (this.available) {
      return this.storage.remove(key, { domain: `${domain}` });
    }
  }

  // Slightly different api from Storage, needs options (path, domain, etc.) sometimes
  clear(prefix, options = {}) {
    if (this.available) {
      const prefixRegex = new RegExp(`^${prefix}`);

      Object.keys(this.storage.get()).forEach((key) => {
        if (!prefix || key.match(prefixRegex)) {
          this.storage.remove(key, options);
        }
      });
    }
  }
}

class Storage {
  constructor(type) {
    try {
      this.storage = window[type];
      this.available = this.isAvailable();
    } catch (err) {
      this.available = false;
    }
  }

  isAvailable() {
    const testKey = '__storage_test__';
    let canSet = false;

    if (this.available !== undefined) {
      return this.available;
    }

    try {
      // to fully test, need to set item
      // http://stackoverflow.com/questions/9077101/iphone-localstorage-quota-exceeded-err-issue#answer-12976988
      this.storage.setItem(testKey, 1);
      this.storage.removeItem(testKey);
      canSet = true;
    } catch (err) {
      canSet = false;
    }

    return canSet;
  }

  set(key, value, options = {}) {
    if (this.available) {
      if (typeof options.expires === 'number') {
        const expires = new Date();
        expires.setMilliseconds(expires.getMilliseconds() + (options.expires * 864e+5));
        options.expires = expires;
      }

      value = JSON.stringify({
        value,
        expires: options.expires
      });

      return this.storage.setItem(key, value);
    }
  }

  get(key, defaultValue = undefined, raw = false) {
    if (this.available) {
      let value = this.storage.getItem(key);
      if (value === null || value === undefined) {
        return defaultValue;
      }

      if (!raw) {
        try {
          value = JSON.parse(value);
        } catch (err) {
          this.remove(key);
          return defaultValue;
        }
      } else {
        return value;
      }

      if (value.expires && new Date() > new Date(value.expires)) {
        this.remove(key);
        return defaultValue;
      }

      return value.value;
    }

    return defaultValue;
  }

  remove(key) {
    if (this.available) {
      return this.storage.removeItem(key);
    }
  }

  clear(prefix) {
    if (this.available) {
      if (!prefix) {
        return this.storage.clear();
      }

      let i;
      let key;
      const prefixRegex = new RegExp(`^${prefix}`);

      for (i = this.storage.length - 1; i > -1; i -= 1) {
        key = this.storage.key(i);
        if (key.match(prefixRegex)) {
          this.storage.removeItem(key);
        }
      }
    }
  }
}

export class LocalStorage extends Storage {
  constructor() {
    super('localStorage');
  }
}

export class SessionStorage extends Storage {
  constructor() {
    super('sessionStorage');
  }
}

export const cookie = new Cookie();

export const local = new LocalStorage();

export const session = new SessionStorage();
