/* eslint-disable */
import $ from 'jquery';
import mediator from '../utils/mediator';

const configureHeroBG = () => {
  const screenSize = window.cigna.screen.breakpoint;
  const accentHeights = [];
  // Get Legal Lines and Meta Accent heights to make photo float in mobile
  $('.hero-long-intro .accent-small').each((i, e) => {
    const accentDiv = $(e);
    accentHeights.push(accentDiv.outerHeight(true));
  });

  const floatPhotoAccentHeight = $('.hero-floating-photo .hero-floating-photo-optional-content').outerHeight(true) || 0;

  const totalAccentHeight = accentHeights.reduce((a, b) => a + b, 0);
  $('.hero-white-left-box').removeClass('d-none');
  switch (screenSize) {
    case 'xs':
      $('.hero-bg').css({ 'background-image': `url(${$('.hero-bg').attr('data-xs-bg')})`, height: '144px' });
      $('.hero-long-intro').css('background-size', `100% calc(${$('.long-intro-content').outerHeight(true)}px + ${totalAccentHeight}px + 184px + 1.5rem)`);
      $('.hero-floating-photo').css('background-size', `100% calc(${$('.hero-floating-photo-main-content').outerHeight(true)}px + ${floatPhotoAccentHeight}px + 184px + 3rem)`);
      $('.hero-white-left-box').css({ left: '0', 'max-width': '100%' });
      $('.hero-full-width-photo').css('margin-bottom', '3rem');
      break;
    case 'sm':
      $('.hero-bg').css({ 'background-image': `url(${$('.hero-bg').attr('data-sm-bg')})`, height: '192px' });
      $('.hero-long-intro').css('background-size', `100% calc(${$('.long-intro-content').outerHeight(true)}px + ${totalAccentHeight}px + 284px + 3rem)`);
      $('.hero-floating-photo').css('background-size', `100% calc(${$('.hero-floating-photo-main-content').outerHeight(true)}px + ${floatPhotoAccentHeight}px + 284px + 3rem)`);
      $('.hero-white-left-box').css({ left: '0', 'max-width': '100%' });
      $('.hero-full-width-photo').css('margin-bottom', '3rem');
      break;
    case 'md':
      $('.hero-bg').css({ 'background-image': `url(${$('.hero-bg').attr('data-md-bg')})`, height: '248px' });
      $('.hero-white-left-box').css({ 'left': 'calc((100% - 724px + 1.5rem) / 2)', 'max-width': 'calc((100% - (100% - 1200px)) * (559/1200)' });
      $('.hero-homepage .hero-white-left-box').css({ 'left': 'calc((100% - 724px + 2rem) / 2)', 'max-width': 'calc((100% - (100% - 1200px)) * (643/1200)' });
      $('.hero-full-width-photo').css('margin-bottom', `calc((${$('.hero-white-left-box').outerHeight(true)}px - (248px - 3rem)) + 4rem)`);
      break;
    case 'lg':
      $('.hero-bg').css({ 'background-image': `url(${$('.hero-bg').attr('data-lg-bg')})`, height: '284px' });
      $('.hero-homepage .hero-bg').css({ 'background-image': `url(${$('.hero-bg').attr('data-lg-bg')})`, height: '360px' });
      $('.hero-white-left-box').css({ 'left': 'calc((100% - 964px + 2rem) / 2)', 'max-width': 'calc((100% - (100% - 1200px)) * (559/1200)' });
      $('.hero-homepage .hero-white-left-box').css({ 'left': 'calc((100% - 964px + 2rem) / 2)', 'max-width': 'calc((100% - (100% - 1200px)) * (643/1200)' });
      $('.hero-full-width-photo:not(".hero-homepage")').css('margin-bottom', `calc((${$('.hero-white-left-box').outerHeight(true)}px - (284px - 3rem)) + 4rem)`);
      $('.hero-homepage.hero-full-width-photo').css('margin-bottom', `calc((${$('.hero-white-left-box').outerHeight(true)}px - (360px - 3rem)) + 4rem)`);
      break;
    case 'xl':
      $('.hero-bg').css({ 'background-image': `url(${$('.hero-bg').attr('data-lg-bg')})`, height: '360px' });
      $('.hero-homepage .hero-bg').css({ 'background-image': `url(${$('.hero-bg').attr('data-lg-bg')})`, height: '547px' });
      $('.hero-white-left-box').css({ 'left': 'calc((100% - 1144px + 1.5rem) / 2)', 'max-width': 'calc((100% - (100% - 1200px)) * (559/1200)' });
      $('.hero-homepage .hero-white-left-box').css({ 'left': 'calc((100% - 1144px + 1.5rem) / 2)', 'max-width': 'calc((100% - (100% - 1200px)) * (643/1200)' });
      $('.hero-full-width-photo:not(".hero-homepage")').css('margin-bottom', `calc((${$('.hero-white-left-box').outerHeight(true)}px - (360px - 3rem)) + 4rem)`);
      $('.hero-homepage.hero-full-width-photo').css('margin-bottom', `calc((${$('.hero-white-left-box').outerHeight(true)}px - (547px - 3rem)) + 4rem)`);
      break;
    default:
      break;
  }
};

export default () => {
  configureHeroBG();
  mediator.on('window:throttledResize', configureHeroBG);
};
