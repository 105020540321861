/* eslint-disable */
import $ from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';

export default () => {
  // Dropdown Menu in Desktop View
  $('#skip-links').on('click', (e) => {
    e.preventDefault();
    switch (e.target.id) {
      case 'skip-links-nav-main':
        if (!window.matchMedia('(max-width: 992px)').matches) {
          $('.site-navigation-level-1 a:first').attr('tabindex', '0').focus().addClass('focus-visible');
        } else {
          $('[data-target=".navbar-control"]').attr('tabindex', '0').focus().addClass('focus-visible');
        }
        break;
      case 'skip-links-main':
        $('html, body').animate({ scrollTop: $('.hero').offset().top }, 700);
        $('[data-ct-component="hero"] :header').first().attr('tabindex', '0').focus();
        break;
      default:
        $('html, body').animate({ scrollTop: $('#footer-row').offset().top - 75 }, 700);
        $('#footer-row').attr('tabindex', '-1').focus();
        break;
    }
  });

  $('.site-navigation-level-2 .dropdown').on({
    'shown.bs.dropdown': (e) => {
      const link = e.relatedTarget;
      const menu = link.nextElementSibling;
      const linkRect = link.getBoundingClientRect();
      const menuRect = menu.getBoundingClientRect();
      const docWidth = document.documentElement.offsetWidth;
      const fitsLeft = linkRect.right - menuRect.width >= 0;
      const fitsRight = menuRect.right <= docWidth;

      const ctComponent = $(link).closest('[data-ct-component]');
      const componentName = ctComponent.attr('data-ct-component');
      ctComponent.attr('data-ct-component', componentName.replace('-open', ''));

      const menuPos = (w) => {
        const mar = (docWidth - w) / 2;
        if (mar >= 0) return mar;
        return 0;
      };

      if (fitsRight) {
        return false;
      }
      if (fitsLeft && !fitsRight) {
        menu.classList.add('dropdown-menu-right');
      } else {
        menu.style.position = 'fixed';
        menu.style.top = `${menuRect.top}px`;
        menu.style.right = `${menuPos(menuRect.width)}px`;
        menu.style.left = `${menuPos(menuRect.width)}px`;
      }
    },
    'hidden.bs.dropdown': (e) => {
      const $this = $(e.relatedTarget);
      const $menu = $this.next('.dropdown-menu');

      const ctComponent = $this.closest('[data-ct-component]');
      const componentName = ctComponent.attr('data-ct-component');
      if (!componentName.endsWith('-open')) {
        ctComponent.attr('data-ct-component', `${componentName}-open`);
      }

      $menu.removeAttr('style');
      $menu.removeClass('dropdown-menu-right');
    }
  });

  $('[data-toggle="slide"]').on('click', (e) => {
    e.preventDefault();
    const $this = $(e.target);
    const targetEle = $this.data('target');
    const $target = $(targetEle);

    $target.toggleClass('open');
    $('html').toggleClass('overflow-hidden');
  });

  function addActiveClass(section, item) {
    const el = $(`[data-nav-${section}='${item}']`);
    const addClass = $(el).hasClass('active') ? null : 'active';
    $(el).addClass(addClass);
  }

  function setAccordionActive(item) {
    const accordionRow = $(`[data-nav-accordion-section='${item}']`).children();
    $(accordionRow[0]).attr('aria-expanded', true);
    $($(accordionRow[0]).children()[0]).removeClass('right').addClass('down');
    $(accordionRow[1]).addClass('show');
  }

  function showLevel2Nav(item) {
    $('.site-navigation-level-2').each((index) => {
      if ($($('.site-navigation-level-2')[index]).attr('data-nav-level2') === item) {
        $($('.site-navigation-level-2')[index]).removeClass('d-none');
      }
    });
  }

  function setActive(section) {
    // get path Array for untagged pages (cigna.com support)
    const pathArray = window.location.pathname.split('/');
    // add active for flex-header nav-level using meta tag
    const metaSec = $(`meta[name='${section}']`).attr('content');
    if (metaSec) {
      if (section === 'section') {
        setAccordionActive(metaSec);
      }

      addActiveClass(section, metaSec);

      if (section !== 'subsection') {
        showLevel2Nav(metaSec);
      }
    // extends logic to pages without tags (support for old navigation)
    } else {
      pathArray.forEach((item) => {
        addActiveClass(section, item.replace('.html', ''));
        setAccordionActive(item.replace('.html', ''));
        showLevel2Nav(item.replace('.html', ''));
      });
    }
  }

  if ($('[data-nav-section]').length) {
    setActive('section');
    setActive('subsection');
  }
};
