/* eslint-disable */
import $ from 'jquery';
import './polyfills';
import { getParams } from '../../utils/url';
import renderTemplate from '../../utils/template';
import noResultsTpl from './views/no-results.tpl.html';
import noSearchCriteriaTpl from './views/no-search-criteria.tpl.html';
import indexTpl from './views/_index.tpl.html';
import mainTpl from './views/main.tpl.html';
import navTabsTpl from './views/nav-tabs.tpl.html';
import paginationTpl from './views/pagination.tpl.html';
import resultsTpl from './views/results.tpl.html';
import showMoreTpl from './views/showmore.tpl.html';
import backToTopTpl from './views/back-to-top.tpl.html';

import paginationBottomCtr from './views/pagination-bottom.tpl.html';
import paginationTopCtr from './views/pagination-top.tpl.html';
import searchResultsCtr from './views/search-results.tpl.html';

import CONSTANTS from './constants';
import { XSS } from './utils';
import Pagination from '../pagination';

const CignaSearch = ({
  INDEX_SITE,
  OPTIONS = {},
  mainTemplate = mainTpl,
  displayForKeyword = false,
  showNavTabs = false,
  navTabsTemplate = navTabsTpl,
  pagingBottom = false,
  pagingBottomTemplate = paginationTpl,
  pagingTop = false,
  pagingTopTemplate = paginationTpl,
  pagingSize = 10,
  resultTemplate = resultsTpl,
}) => {
  const subDomain = window.location.hostname.split(/(\.)|(-)/g)[0].toLowerCase();
  let ACTIVE_ENV = CONSTANTS.hasOwnProperty(subDomain) ? subDomain : 'qa';
  let ACTIVE_ENV_SITE = INDEX_SITE;

  if (subDomain === 'www' || window.location.hostname.split('.').length === 2) {
    ACTIVE_ENV = 'prod';
  } else {
    if (typeof window.cigna !== 'undefined' && window.cigna.hasOwnProperty('ELASTICSEARCH')) {
      const { ELASTICSEARCH } = window.cigna;
      const { INDEX_SITE, MAPPING_ENV } = ELASTICSEARCH;
      if (INDEX_SITE) {
        ACTIVE_ENV_SITE = INDEX_SITE;
      }
      if (MAPPING_ENV) {
        ACTIVE_ENV = MAPPING_ENV[subDomain] || ACTIVE_ENV;
      }
    } else if (OPTIONS.hasOwnProperty('MAPPING_ENV')) {
      const { MAPPING_ENV } = OPTIONS;
      ACTIVE_ENV = MAPPING_ENV[subDomain] || ACTIVE_ENV;
    } else if (subDomain.indexOf('localhost') > -1) {
      ACTIVE_ENV = 'dev';
    }
  }

  const API_URL = CONSTANTS[ACTIVE_ENV];
  const INDEX = `${ACTIVE_ENV_SITE}-${ACTIVE_ENV}`;

  const goAndSearch = (search) => {
    window.location.replace(`${window.location.origin}${window.location.pathname}${search}`);
  };

  const searchWord = getParams(window.location.search).query ? getParams(window.location.search).query.replace(/\+/g, ' ') : '';
  if (XSS.is(window.location.search)) { goAndSearch(window.location.search.replace(/(query=).*(&|$)/, 'query=')); return false; }
  else if (XSS.hasChars(searchWord)) { goAndSearch(window.location.search.replace(/(query=).*(&|$)/, `query=${XSS.sanitize(searchWord)}`)); return false; }

  const isSingleWord = !/\s/g.test(searchWord.trim()) && searchWord.indexOf('+') === -1; // Check if spaces or '+' between words
  const searchType = isSingleWord ? 'wildcard' : 'exact';
  let skip = getParams(window.location.search).from || 0;
  let defaultDCRs = {
    backToTopLabel: 'BACK TO TOP',
    displayResultsLabel: 'Displaying results for [keyword]',
    keywordErrorLabel: 'Your search for "[keyword]" did not match any pages or documents.',
    loadingLabel: 'Loading...',
    multiResultLabel: 'Results',
    noSearchCriteriaMsg: 'Please submit a search term.',
    oneResultLabel: 'Result',
    pagination: {
      nextButtonLabel: 'Next',
      prevButtonLabel: 'Previous',
    },
    showMoreLabel: 'Show Next',
    showingOfLabel: 'Showing [rangeStart] - [rangeEnd] of [resultCount]',
    suggestionLabel: 'Suggestions',
    suggestionList: [
      'Make sure all words are spelled correctly.',
      'Try different keywords.',
      'Try more general keywords.',
    ],
    navTabsLabel: ['Cigna ([total])'],
  };

  const extractPDFFileName = (url) => {
    let filename = url.substring(url.lastIndexOf('/') + 1);
    filename = filename
      .toLowerCase()
      .split('-')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

    return `${filename.substr(0, filename.lastIndexOf('.'))} [PDF]`;
  };

  const renderSearchTemplate = () => {
    const backToTop = renderTemplate(backToTopTpl, {
      backToTopLabel: defaultDCRs.backToTopLabel,
    });
    const navTabsLabel = [];
    if (showNavTabs) {
      if (defaultDCRs.navTabsLabel.length > 0) {
        let navTabIndex = 0;
        defaultDCRs.navTabsLabel.forEach((navTabLabel) => {
          navTabsLabel.push(navTabLabel.replace('[total]', `<span class="search-results__total" data-tab-sublabel="${navTabIndex}">0</span>`));
          navTabIndex += 1;
        });
      }
    }
    const renderNavTabs = renderTemplate(navTabsTemplate, {
      navTabsLabel,
    });
    const main = renderTemplate(mainTemplate, {
      backToTop,
      displayResultsLabel: displayForKeyword ? defaultDCRs.displayResultsLabel.replace('[keyword]', `<strong>${searchWord}</strong>`) : undefined,
      navTabs: renderNavTabs,
      paginationBottom: paginationBottomCtr,
      paginationTop: paginationTopCtr,
      searchResults: searchResultsCtr,
    });
    const cignaSearchHtml = renderTemplate(indexTpl, {
      loadingLabel: defaultDCRs.loadingLabel,
      main,
    });
    $('#searchTemplate').append(cignaSearchHtml);
  };

  const renderNoResultsTemplate = () => {
    const noSearchResultsHtml = renderTemplate(noResultsTpl, {
      keywordErrorLabel: defaultDCRs.keywordErrorLabel.replace(
        '[keyword]',
        `<strong>${searchWord}</strong>`
      ),
      suggestionLabel: defaultDCRs.suggestionLabel,
      suggestionList: defaultDCRs.suggestionList,
    });
    $('.itemized-results').html(noSearchResultsHtml).show();
  };

  const renderNoSearchCriteriaTemplate = (message = defaultDCRs.noSearchCriteriaMsg) => {
    const noSearchCriteria = renderTemplate(noSearchCriteriaTpl, {
      noSearchCriteriaMsg: message,
    });
    $('#searchTemplate').html(noSearchCriteria).show();
  };

  const updateResults = () => {
    // Use for Pagination
    const params = `?index=${INDEX}&query=${encodeURIComponent(searchWord.toLowerCase())}${
      isSingleWord ? '*' : ''
    }&from=${skip}&type=${searchType}`;
    const API = `${API_URL}${params}`;

    fetch(API)
      .then((response) => response.json())
      .then((data) => {
        if (data.results) {
          const searchedKey = new RegExp(searchWord !== '' ? searchWord : null, 'ig');
          const searchResults = renderTemplate(resultTemplate, {
            extractPDFFileName,
            results: data.results,
            searchedKey,
            url: window.location.origin,
          });

          $('[data-html-load="search-results"]').html(searchResults);
        }
      })
      .catch(function (error) {
        // Oh no, something went wrong!
        console.log(error);
        renderNoResultsTemplate();
        $('.search-results-spinner').hide();
      });
  };

  const loadMore = (tab) => {
    // Use for Show More
    // You can add more tabs as they come
    const tabsAvailable = {
      cignaMoreResults: {
        id: 'cignaTab',
        data: 'results',
        totalCount: 'total',
        tabName: 'cignaMoreResults',
        rangeEnd: 'cignaRangeEnd',
      },
    };
    const currentResultsAmount = $(`#${tabsAvailable[tab].id} .csng-result-item`).length;
    const offset = currentResultsAmount === 10 ? 10 : currentResultsAmount;
    const params = `?index=${INDEX}&query=${encodeURIComponent(searchWord.toLowerCase())}${
      isSingleWord ? '*' : ''
    }&from=${skip}&type=${searchType}`;
    const API = `${API_URL}${params}`;
    const resultsLeft = (offset / 10) % 2;
    if (resultsLeft === Math.floor(resultsLeft)) {
      // TODO: USE FETCH anywhere you are ajax calling
      fetch(API)
        .then((response) => response.json())
        .then((data) => {
          if (data.results) {
            // const dataTab = tabsAvailable[tab].data.split('.').reduce((a, b) => a[b], data.results);
            const dataTab = data.results;
            const dataTotal = tabsAvailable[tab].totalCount
              .split('.')
              .reduce((a, b) => a[b], data.total);
            const searchedKey = new RegExp(searchWord !== '' ? searchWord : null, 'ig');
            if (dataTab.length) {
              const searchResults = renderTemplate(resultTemplate, {
                extractPDFFileName,
                results: data.results,
                searchedKey,
                url: window.location.origin,
              });

              $(`#${tabsAvailable[tab].id}.itemized-results [data-html-load="search-results"]`).append(searchResults);
              $(`[data-range-end='${tabsAvailable[tab].rangeEnd}']`).text(offset + dataTab.length);
              if (offset + dataTab.length === dataTotal) {
                $(`[data-next-button='${tabsAvailable[tab].tabName}']`).hide();
              }
            }
            $('.language-es').html('<span class="espanol-tag">en&nbsp;español</span>');
          }
          $(`[data-next-button='${tabsAvailable[tab].tabName}']`).removeClass('disabled');
        })
        .catch(function (error) {
          // Oh no, something went wrong!
          console.log(error);
          renderNoResultsTemplate();
          $('.search-results-spinner').hide();
        });
    }
  };

  const runMoreResultsClick = () => {
    $('.search-results__pagination').on('click', '[data-next-button]', (e) => {
      $(e.target).addClass('disabled');
      skip += 10;
      const nextButtonId = $(e.target).attr('data-next-button');
      loadMore(nextButtonId);
    });
  };

  const init = () => {
    $('input.typeahead').val(searchWord);
    $('.search-results__spinner').show();

    const params = `?index=${INDEX}&query=${encodeURIComponent(searchWord.toLowerCase())}${
      isSingleWord ? '*' : ''
    }&from=${skip}&type=${searchType}`;
    const API = `${API_URL}${params}`;

    fetch(API)
      .then((response) => response.json())
      .then((data) => {
        // handle success
        if (data.results) {
          // Change Tab Result Count
          $('.search-results__spinner').hide();
          $('.search-results__total').text(data.total || '0');
          $('#main-template').show();

          const searchedKey = new RegExp(searchWord !== '' ? searchWord : null, 'ig');
          const page = parseInt(1, 10);
          // eslint-disable-next-line max-len
          const cignaPageSize = data.results.length >= 10 ? 10 : data.results.length;
          // eslint-disable-next-line max-len
          const cignaResultCount = data.total;
          const totalCignaPages = Math.ceil(cignaResultCount / cignaPageSize);
          const cignaPage = Math.min(page, totalCignaPages);

          // Check if Cigna search results came back
          if (data.results.length > 0) {
            const searchResults = renderTemplate(resultTemplate, {
              extractPDFFileName,
              results: data.results,
              searchedKey,
              url: window.location.origin,
            });
            $('[data-html-load="search-results"]').append(searchResults);

            if (pagingTop) {
              const pagingTopHtml = renderTemplate(pagingTopTemplate, {
                page: cignaPage,
                pageSize: cignaPageSize,
                totalPages: totalCignaPages,
                resultCount: cignaResultCount,
                rangeStart: cignaPageSize * (cignaPage - 1) + 1,
                rangeEnd: cignaPageSize,
                rangeEndId: 'cignaRangeEnd',
                nextId: 'cignaMoreResults',
                nextButtonLabel: defaultDCRs.pagination.nextButtonLabel,
                oneResultLabel: defaultDCRs.oneResultLabel,
                multiResultLabel: defaultDCRs.multiResultLabel,
              });
              $('[data-html-load="pagination-top"]')
                .append(`<div class="mt-3">${pagingTopHtml}</div>`);
            }
            if (pagingBottom) {
              $('[data-html-load="pagination-bottom"]')
                .append('<div class="pagination__container" data-pagination-controller></div>');

              Pagination({
                totalItems: data.total,
                maxPages: pagingSize,
                nextButtonLabel: defaultDCRs.pagination.nextButtonLabel,
                prevButtonLabel: defaultDCRs.pagination.prevButtonLabel,
                onChange: (page) => {
                  skip = (page - 1) * pagingSize;
                  updateResults();
                },
              });
            }
            if (!pagingTop && !pagingBottom) {
              // Default template
              const showMoreHtml = renderTemplate(showMoreTpl, {
                page: cignaPage,
                pageSize: cignaPageSize,
                totalPages: totalCignaPages,
                resultCount: cignaResultCount,
                rangeStart: cignaPageSize * (cignaPage - 1) + 1,
                rangeEnd: cignaPageSize,
                rangeEndId: 'cignaRangeEnd',
                nextId: 'cignaMoreResults',
                nextButtonLabel: defaultDCRs.showMoreLabel,
                oneResultLabel: defaultDCRs.oneResultLabel,
                multiResultLabel: defaultDCRs.multiResultLabel,
                showingOfLabel: defaultDCRs.showingOfLabel
                  .replace('[rangeStart]', cignaPageSize * (cignaPage - 1) + 1)
                  .replace('[rangeEnd]', `<span id="cignaRangeEnd" data-range-end="cignaRangeEnd">${cignaPageSize}</span>`)
                  .replace('[resultCount]', cignaResultCount),
              });
              $('[data-html-load="pagination-top"], [data-html-load="pagination-bottom"]')
                .append(`<div class="mt-3">${showMoreHtml}</div>`);
            }
          } else {
            // If no results, add no results template
            renderNoResultsTemplate();
          }

          $('.language-es').html('<span class="espanol-tag">en&nbsp;español</span>');
        } else if (data.hasOwnProperty('message')) {
          renderNoSearchCriteriaTemplate(data.message);
        } else {
          renderNoResultsTemplate();
          $('.search-results__spinner').hide();
        }
        runMoreResultsClick();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        // Oh no, something went wrong!
        renderNoResultsTemplate();
        $('.search-results__spinner').hide();
      });
  };

  const submitSearch = () => {
    // To avoid append '+' between words in search query
    $('.elastic-search').submit((e) => {
      e.preventDefault();
      const query = encodeURIComponent(
        XSS.sanitize($(e.target).find('input[name="query"]').val().trim())
      );

      if (window.location.search.indexOf('query') > -1)
        goAndSearch(window.location.search.replace(/(query=).*(&|$)/, `query=${query}`));
      else if (window.location.search !== '')
        goAndSearch(`${window.location.search}&query=${query}`);
      else goAndSearch(`?query=${query}`);
    });
  };

  if (typeof $('#search-dcr-json-data').html() !== 'undefined')
    defaultDCRs = JSON.parse($('#search-dcr-json-data').html());
  if (searchWord !== '') {
    renderSearchTemplate();
    init();
  } else {
    renderNoSearchCriteriaTemplate();
  }
  submitSearch();
};

export default CignaSearch;
