import $ from 'jquery';
import SearchResults from './search-results';

// const { ELASTICSEARCH } = window.cigna;

export default () => {
  if ($('[data-search-results]').length) {
    SearchResults({
      INDEX_SITE: 'index-cignaglobaluvsl',
    });
  }
};
