import $ from 'jquery';

/**
 * Trim text and remove extra whitespace
 */
export const trim = (text) => text && $.trim(text.replace(/\s\s+/g, ' '));

/**
 * Escapes a string for use in HTML.
 */
export const escapeHTML = (str) =>
  (str || '').replace(
    /[&<>'"]/g,
    (tag) =>
      ({
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        "'": '&#39;',
        '"': '&quot;',
      }[tag] || tag)
  );

/**
 * Unescapes escaped HTML characters.
 */
export const unescapeHTML = (str) =>
  (str || '').replace(
    /&amp;|&lt;|&gt;|&#39;|&quot;/g,
    (tag) =>
      ({
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&#39;': "'",
        '&quot;': '"',
      }[tag] || tag)
  );

/**
 * Converts a string to kebab case.
 * @param {String} str string to convert
 */
export const toKebabCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('-');

/**
 * Capitalizes every word of a string
 * @param {String} str string to convert
 */
export const capitalizeEveryWord = (str) =>
  str && str.replace(/\b[a-z]/g, (char) => char.toUpperCase());

export default trim;
