import $ from 'jquery';
import template from '../utils/template';
import ArticleFilter from './filters/article-filter';
import config from '../config';

export default () => {
  const dateFormat = (value) => {
    const date = new Date(value);
    const month = date.toLocaleString(undefined, { month: 'short' });
    return `${month} ${date.getFullYear()}`;
  };
  const render = (tpl, element, data) => {
    const markup = template($(tpl).html(), data);
    $(element).html(markup);
  };
  const fetchData = async (path) => {
    const res = await fetch(`${config.staticPath}/cignaglobal-uvsl/json/blogs/allblogs.json`);
    const results = await res.json();

    const blogs = results.blogs.reduce((acc, release) => {
      const { Date } = release;
      acc[Date] = release;
      return acc;
    }, {});

    /* eslint-disable no-param-reassign */
    const tags = results.blogs.reduce((a, { BlogTags }) => {
      const b = BlogTags.split('|')[0];
      const c = BlogTags.split('|')[1];
      a[b] = c;

      return a;
    }, {});

    // Filter
    render('#blog-tag-template', '[data-template-load="blog-tags"]', { tags });

    if ($('[data-template-load="blog-image-teasers"]').length) {
      render('#blog-teaser-template', '[data-template-load="blog-image-teasers"]', {
        blogs: results.blogs,
        dateFormat,
      });
    }

    if (path === 'allblogs') {
      render('#blog-template', '[data-template-load="blogs"]', {
        blogs,
        dateFormat,
      });
    } else {
      const topicRes = await fetch(`
      ${config.staticPath}/cignaglobal-uvsl/json/blogs/tags/${path}.json
      `);
      const topicResults = await topicRes.json();

      const topicBlogs = topicResults.blogs.reduce((acc, release) => {
        const { Date } = release;
        acc[Date] = release;
        return acc;
      }, {});

      render('#blog-template', '[data-template-load="blogs"]', {
        blogs: topicBlogs,
        dateFormat,
      });
    }
  };
  (async () => {
    if (
      $('[data-template-load="blogs"]').length ||
      $('[data-template-load="blog-image-teasers"]').length
    ) {
      const urlPath = window.location.pathname.split('/');
      const jsonPath =
        urlPath[urlPath.length - 1] === 'index' ||
        urlPath[urlPath.length - 1] === 'blog' ||
        urlPath[urlPath.length - 1] === ''
          ? 'allblogs'
          : urlPath[urlPath.length - 1];
      await fetchData(jsonPath);
      ArticleFilter();
    }
  })();
};
