import $ from 'jquery';
import renderTemplate from '../../utils/template';
import landing from './views/landing.tpl.html';
import finderLogic from './finder-logic';
import finderData from './finder.json';

const renderApp = () => {
  // i18Prep(i18next, landing, '#finder');
  const appRoot = '#finder';
  const html = renderTemplate(landing, finderData);
  $(appRoot).append(html);
};

export default () => {
  const finderRoot = document.querySelector('#finder');

  if (finderRoot) {
    renderApp();
    finderLogic();
  }
};
