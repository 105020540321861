import $ from 'jquery';
import mediator from '../utils/mediator';

export const initialPromoBG = () => {
  const screenSize = window.cigna.screen.breakpoint;
  $('.promo-bg').each((index, el) => {
    switch (screenSize) {
      case 'xs':
        $(el).css({ 'background-image': `url(${$(el).attr('data-xs-bg')})` });
        break;
      case 'sm':
        $(el).css({ 'background-image': `url(${$(el).attr('data-sm-bg')})` });
        break;
      case 'md':
        $(el).css({ 'background-image': `url(${$(el).attr('data-md-bg')})` });
        break;
      case 'lg':
        $(el).css({ 'background-image': `url(${$(el).attr('data-lg-bg')})` });
        break;
      case 'xl':
        $(el).css({ 'background-image': `url(${$(el).attr('data-lg-bg')})` });
        break;
      default:
        break;
    }
  });

  mediator.on('window:throttledResize', initialPromoBG);
};

export default () => {
  initialPromoBG();
};
