import $ from 'jquery';
import config from '../../config';
import renderTemplate from '../../utils/template';
import paginationTpl from './views/pagination.tpl.html';

const init = ({
  currentPage = 1,
  totalItems = 0,
  itemsPerPage = 10,
  maxPages = 10,
  template = paginationTpl,
  nextButtonLabel = 'Next',
  prevButtonLabel = 'Previous',
  onChange = Function,
}) => {
  const loadPagination = () => {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // ensure current page isn't out of range
    /* eslint-disable no-param-reassign */
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    } else if (window.location.hash.indexOf('page') !== -1) {
      window.location.hash = `page${currentPage}`;
    }

    let startPage = 1;
    let endPage = totalItems;
    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    const lastPage = totalPages;
    const min = startPage;
    const max = endPage;
    const html = renderTemplate(template, {
      current: Number(currentPage),
      totalItems,
      lastPage,
      range: [min, max],
      nextButtonLabel,
      prevButtonLabel,
      svgPath: config.svgPath,
    });
    $('[data-pagination-controller]').html(html);
  };

  loadPagination();

  $(document).on('click', '[data-pagination-click]', (e) => {
    currentPage = e.target.getAttribute('href').replace('#page', '');
    $('body, html').animate({ scrollTop: $('body').offset().top - 0 }, 0);

    if (typeof onChange === 'function') onChange(currentPage);
    loadPagination();
  });
};

export default (cfg) => {
  init(cfg);
};
