/* eslint-disable import/prefer-default-export */
export const generateServiceReferenceId = () => {
  const part1 = 1000000000 + Math.floor(Math.random() * 9000000000);
  const part2 = 100000000 + Math.floor(Math.random() * 900000000);
  return `${part1}-${part2}`;
};

export const XSS = {
  regex: /(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script/ig, // eslint-disable-line
  chars: /(<\s*)|(>\s*)|(\$\s*)|(\/\s*)|(\(\s*)|(\)\s*)|(:\s*)|(%\s*)|(@\s*)|(\*\s*)|(\^\s*)|(\{\s*)|(}\s*)/ig, // eslint-disable-line
  is(str) {
    return this.regex.test(decodeURIComponent(str));
  },
  hasChars(str) {
    return this.chars.test(decodeURIComponent(str));
  },
  sanitize(input) {
    return decodeURIComponent(input).replace(/(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script|(<\s*)|(>\s*)|(\$\s*)|(\/\s*)|(\(\s*)|(\)\s*)|(:\s*)|(%\s*)|(@\s*)|(\*\s*)|(\^\s*)|(\{\s*)|(}\s*)/ig, ''); // eslint-disable-line
  },
};
