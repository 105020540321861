/* eslint-disable no-unused-vars */
import $ from 'jquery';
import './polyfills'; // keep this first
import 'focus-visible';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/scrollspy';
import initAccordions from './components/accordions';
import initAlerts from './components/alerts';
import initCurrencySwitcher from './components/currency-switcher';
import languageSwitcher from './components/language-switcher';
import initEvents from './events';
import initLinks from './links';
import initAnalytics from './analytics';
import initPagination from './components/pagination';
import initPromos from './components/promos';
import initTooltip from './components/tooltip';
import initCb from './components/content-blocks';
import initHeroes from './components/heroes';
import initNavigation from './components/navigation';
import initGlobalHeader from './components/global-header';
import initLanguageChooser from './components/language-chooser';
import initBlockSelection from './components/block-selection';
import initBackToTop from './components/back-to-top';
// import ArticleFilter from './components/filters/article-filter';
import CountryFilter from './components/filters/country-filter';
import initShareButtons from './components/social/share-buttons';
import Form from './components/forms';
import initForms from './forms';
import headerSticky from './components/header-sticky';
import quoteAlertSticky from './components/quote-alert-sticky';
import countryGuide from './components/country-guide';
import Newsroom from './components/newsroom';
import Search from './components/search';
import Finder from './components/finder';
import initTables from './components/tables';
import '../scss/index.scss';

if (!window.$) window.$ = $;
if (!window.jQuery) window.jQuery = $;

initEvents();
initLinks();
initAccordions();
initAlerts();
initTooltip();
initPromos();
initCb();
initCurrencySwitcher();
languageSwitcher();
initHeroes();
initNavigation();
initGlobalHeader();
initLanguageChooser();
initBlockSelection();
initBackToTop();
// ArticleFilter();
CountryFilter();
initShareButtons();
Form.autocomplete('country');
initForms();
headerSticky();
quoteAlertSticky();
countryGuide();
Newsroom();
Search();
Finder();
initTables();
initAnalytics();

if (process.env.SHOW_EXAMPLES && document.location.pathname.endsWith('pagination.html')) {
  initPagination({
    totalItems: 50,
    maxPages: 10,
    onChange: () => {
      // Callback to update the results
    },
  });
}
