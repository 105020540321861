import $ from 'jquery';

const clickHandler = (evt) => {
  evt.preventDefault();
  const link = $(evt.currentTarget);
  const target = link.data('target') ? link.data('target') : 'body';
  let offset = link.data('offset') ? link.data('offset') : 0;
  const duration = link.data('duration') ? parseInt(link.data('duration'), 10) : 0;

  const navsOnPage = $('nav');
  const navtoUse = navsOnPage.filter((i, nav) =>
    ['sticky', 'fixed'].some((t) => t === $(nav).css('position') && $(nav).outerHeight() > 0)
  );
  if (navtoUse && navtoUse.length) {
    const navOffset = navtoUse.outerHeight(true) + offset;
    offset = navOffset;
  }

  $('body,html').animate(
    {
      scrollTop: $(target).offset().top - offset,
    },
    duration
  );

  $(target).animate(
    {
      scrollTop: 0,
    },
    duration
  );

  return false;
};

export default () => {
  $('.back-to-top').on('click', clickHandler);
};
