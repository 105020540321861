import $ from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/alert';

export default () => {
  $(document.body).on('click', '[data-dismiss="browser-warning"]', (evt) => {
    evt.preventDefault();
    const alertBox = $(evt.currentTarget).closest('.alert-banner');
    alertBox.removeClass('show').detach().remove();
  });
};
