import $ from 'jquery';

const updateProgress = (value) => {
  const progressBar = document.querySelector('.finder__progress .progress__bar');
  const progressValue = document.querySelector('.progress-value');
  const progressTitle = document.querySelector('.progress-title');

  progressBar.setAttribute('aria-valuenow', value);
  progressBar.style.width = `${value}%`;

  if (value === '0') progressValue.innerHTML = 'Not Started - Takes less than a minute';
  else progressValue.innerHTML = `${value}%`;

  if (value === '100') progressTitle.innerHTML = 'Results';
  else if (value === '0') progressTitle.innerHTML = 'Start';
  else progressTitle.innerHTML = 'Progress';
};

const handleChange = (evt) => {
  const ele = evt.target;
  const finder = document.querySelector('#finder-app');
  const results = document.querySelector('#finder-results');
  const showQuestion = finder.querySelector(`#${ele.value}`);
  const showSite = results.querySelector(`#${ele.value}`);
  const btnSubmit = finder.querySelector('#find-button');
  const fieldOverlay = ele.nextElementSibling;
  // const wrapper = document.querySelector('.wrapper');
  const progress = document.querySelector('.progress-statusbar');
  const field = ele.parentNode;

  field.classList.add('field--set');
  ele.setAttribute('disabled', 'disabled');

  fieldOverlay.classList.add('field__overlay--set');

  if (showQuestion) {
    showQuestion.classList.remove('field--hidden');
    updateProgress(showQuestion.dataset.progress);
  }

  if (showSite) {
    btnSubmit.classList.remove('btn--hidden');
    btnSubmit.dataset.target = ele.value;
    updateProgress('100');
  }

  if (ele.id === 'question-1') {
    if (!finder.classList.contains('js-animated')) {
      $('html, body').animate(
        {
          scrollTop: progress.offsetTop - 85,
        },
        500
      );
    }
    // wrapper.classList.add('sticky');
    finder.classList.add('js-animated');
  }

  ele.parentNode.classList.remove('field--edit');
};

const handleFieldReset = (ele) => {
  const select = ele.querySelector('select');
  const fieldOverlay = ele.querySelector('.field__overlay');
  const wrapper = document.querySelector('.wrapper');
  const finder = document.querySelector('#finder-app');
  const findWebsiteBtn = document.getElementById('find-button');

  select.disabled = '';
  select.value = '';
  fieldOverlay.classList.remove('field__overlay--set');
  ele.classList.remove('field--set');
  ele.querySelector('label').classList.remove('field__label--hidden');

  findWebsiteBtn.classList.add('btn--hidden');

  if (ele.id !== 'field-1') {
    ele.classList.add('field--hidden');
    wrapper.classList.remove('sticky');
    finder.classList.remove('js-animated');
  }
};

const handleReset = () => {
  const finder = document.querySelector('#finder-app');
  const finderQuestions = finder.querySelectorAll('.field:not(.field--hidden)');
  const results = document.querySelector('#finder-results');
  const visibleSites = results.querySelector('.sites:not(.site--hidden)');
  const btnSubmit = finder.querySelector('#find-button');
  const btnVisit = document.querySelector('#visit-button');
  const wrapper = document.querySelector('.wrapper');
  const progress = document.querySelector('.progress-statusbar');

  btnSubmit.dataset.target = '';
  btnSubmit.classList.add('btn--hidden');
  wrapper.classList.remove('sticky');
  btnVisit.setAttribute('href', '#0');

  if (visibleSites) visibleSites.classList.add('site--hidden');

  results.classList.add('results--hidden');

  Array.from(finderQuestions).forEach((ele) => handleFieldReset(ele));

  finder.classList.remove('finder--hidden', 'js-animated');

  $('html, body').animate(
    {
      scrollTop: progress.offsetTop - 85,
    },
    500
  );

  finder.querySelector('#question-1').focus();

  updateProgress('0');
};

const pushDatalayer = () => {
  const finderProgress = document.querySelector('.progress-value').textContent;
  const finderFields = $('select:visible');

  const trackingObj = [...finderFields].reduce(
    /* eslint-disable no-param-reassign */
    (obj, field, index) => {
      obj[`Resp${index + 1}`] = field.selectedOptions[0].text;
      return obj;
    },
    {
      event: 'FormTracking',
      ProgCheck: finderProgress,
      Resp1: '',
      Resp2: '',
      Resp3: '',
      Resp4: '',
      Resp5: '',
    }
  );

  setTimeout(() => {
    window.dataLayer.push(trackingObj);
  }, 1000);
};

const handleShow = (evt) => {
  const btn = evt.target;
  const results = document.querySelector('#finder-results');
  const finder = document.querySelector('#finder-app');
  const siteToShow = results.querySelector(`#${btn.dataset.target}`);
  const btnVisit = document.querySelector('#visit-button');
  const overlays = document.querySelectorAll('.field__overlay--set');

  btnVisit.setAttribute('href', siteToShow.dataset.siteUrl);

  results.classList.remove('results--hidden');

  siteToShow.classList.remove('site--hidden');

  Array.from(overlays).forEach((ele) => ele.classList.remove('field__overlay--set'));

  finder.classList.add('finder--hidden');
};

const handleOverlayClick = (evt) => {
  const ele = evt.target;
  const select = ele.previousElementSibling;
  const fieldClicked = ele.parentNode;
  const fieldWrapper = fieldClicked.parentNode;
  const { progress } = fieldClicked.dataset;
  const btnSubmit = document.querySelector('#find-button');

  if (!select.disabled) return;

  btnSubmit.classList.add('btn--hidden');
  ele.classList.remove('field__overlay--set');
  ele.parentNode.classList.remove('field--set');
  ele.parentNode.classList.add('field--edit');
  select.removeAttribute('disabled');
  select.focus();
  select.value = '';

  const fieldsToReset = fieldWrapper.querySelectorAll(
    '.field--edit ~ .field--set, .field--edit ~ .field:not(.field--hidden)'
  );
  Array.from(fieldsToReset).forEach((elem) => handleFieldReset(elem));

  updateProgress(progress);
};

export default () => {
  const finder = document.querySelector('#finder-app');
  const finderFields = finder.querySelectorAll('select');
  const btnSubmit = finder.querySelector('#find-button');
  const btnReset = document.querySelector('#reset');

  Array.from(finderFields).forEach((ele) => ele.addEventListener('change', handleChange));

  btnSubmit.addEventListener('click', handleShow);

  btnReset.addEventListener('click', handleReset);

  document.addEventListener(
    'click',
    (e) => {
      // loop parent nodes from the target to the delegation node
      for (let { target } = e; target && target !== this; target = target.parentNode) {
        if (target.matches && target.matches('.field__overlay--set')) {
          handleOverlayClick.call(target, e);
          break;
        }
      }
    },
    false
  );

  const IDLE_WAIT_TIME = 1000;
  let idelTimer;

  const resetIdleTimer = () => {
    clearTimeout(idelTimer);
    idelTimer = setTimeout(pushDatalayer, IDLE_WAIT_TIME);
  };

  if (finderFields.length) {
    resetIdleTimer();
    $('#finder-app select').on('change', resetIdleTimer);
  }
};
