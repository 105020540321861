import $ from 'jquery';

/*
 * getPseudoElementProperty
 *
 * Gets a psuedo element property of a given selector
 */
export const getPseudoElementProperty = (element, psuedoElement, property) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  window
    .getComputedStyle(document.querySelector(element), psuedoElement)
    .getPropertyValue(property)
    .replace(/["']/g, '');

export const getMetaProperty = (name, attr) => $(`meta[name=${name}]`).attr(attr);

export const getMetaContent = (name) => getMetaProperty(name, 'content');

export const checkMetaToggle = (name) => {
  // look for "off"
  const value = getMetaContent(name);
  return !value || value.toLowerCase() !== 'off';
};
