import $ from 'jquery';
import Parsley from 'parsleyjs';
import defaultValidationUI from './util';
import { unescapeHTML } from '../utils/text';
import defaultSuccessMessage from '../views/autovalidate-default-success-msg.tpl.html';
import defaultErrorMessage from '../views/autovalidate-default-error-msg.tpl.html';

/**
 * Checks if value is a valid class (.) or id (#) selector.
 */
const isSelector = (value) => {
  const $el = $(value);
  return $el.length && ($el.attr('id') === value.slice(1) || $el.hasClass(value.slice(1)));
};

Parsley.addValidator('requiredIf', {
  validate: (value, requirement) => {
    if ($(requirement).is(':checked')) {
      return !!value;
    }
    return true;
  },
  messages: {
    en: 'This value is required.',
  },
});

export default (setupValidationUI = defaultValidationUI) => {
  $(document).on('submit', '[data-autovalidate]', (evt) => {
    evt.preventDefault();
    const $form = $(evt.currentTarget);
    setupValidationUI($form);
    const formData = {};
    $form.serializeArray().forEach((x) => {
      formData[x.name] = x.value;
    });

    if ($('input[type="tel"]').length) {
      const inputTelName = $('input[type="tel"]').attr('name');
      const inputTelValue = $('input[type="tel"]').val();
      const intlNumberData =
        window.intlTelInputGlobals.instances[
          parseInt($('input[type="tel"]').attr('data-intl-tel-input-id'), 10)
        ];
      const dialCodeSelected = intlNumberData.selectedCountryData.dialCode;
      const formattedNumber = intlNumberData.getNumber();
      formData[inputTelName] = formattedNumber.length
        ? formattedNumber
        : `+${dialCodeSelected}${inputTelValue}`;
    }

    $form.parsley().validate();

    if ($form.parsley().isValid()) {
      $form.find('[type=submit]').prop('disabled', true);

      const successAction = $form.data('autovalidateAction');

      if (['replace', 'redirect'].indexOf(successAction) >= 0) {
        const promise = $.ajax({
          url: $form.attr('action'),
          method: $form.attr('method') || 'POST',
          data: $.param(formData),
        });

        // eslint-disable-next-line no-unused-vars
        promise.then((data) => {
          let successMessageOrURL = unescapeHTML($form.data('autovalidateSuccess'));

          if (successAction === 'replace') {
            if (isSelector(successMessageOrURL)) {
              successMessageOrURL = $(successMessageOrURL).html();
            }

            let target = $form.data('autovalidateReplace');
            if (target && isSelector(target)) {
              target = $(target);
            } else {
              target = $form;
            }

            successMessageOrURL = successMessageOrURL || defaultSuccessMessage;
            target.html(successMessageOrURL);
          } else {
            // redirect
            window.location.assign(successMessageOrURL);
          }
        });

        // eslint-disable-next-line no-unused-vars
        promise.catch((error) => {
          let errorMessage = unescapeHTML($form.data('autovalidateError'));
          if (isSelector(errorMessage)) {
            errorMessage = $(errorMessage).html();
          }
          errorMessage = errorMessage || defaultErrorMessage;
          $form.html(errorMessage);
        });

        return false;
      }

      // successAction is undefined, normal non-ajax form submission
      return true;
    }

    return false;
  });
};
