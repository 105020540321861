import $ from 'jquery';

/* eslint no-param-reassign: ["error", { "props": false }] */
const init = (el, arr = []) => {
  /* the autocomplete function takes two arguments,
   the text field element and an array of possible autocompleted values: */
  $(el).parent().addClass('autocomplete');
  $(document).on('keyup', el, (event) => {
    let currentFocus;
    const inp = event.target;

    const removeActive = (x) => {
      /* a function to remove the "active" class from all autocomplete items: */
      for (let i = 0; i < x.length; i + 1) {
        x[i].classList.remove('autocomplete-active');
      }
    };

    const addActive = (x) => {
      /* a function to classify an item as "active": */
      if (!x) return false;
      /* start by removing the "active" class on all items: */
      removeActive(x);
      if (currentFocus >= x.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = x.length - 1;
      /* add class "autocomplete-active": */
      x[currentFocus].classList.add('autocomplete-active');

      return true;
    };

    const closeAllLists = (elmnt) => {
      /* close all autocomplete lists in the document,
       except the one passed as an argument: */
      const x = document.getElementsByClassName('autocomplete-items');
      for (let i = 0; i < x.length; i += 1) {
        if (elmnt !== x[i] && elmnt !== inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    };

    /* execute a function when someone writes in the text field: */
    /* eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": true }] */
    inp.addEventListener('input', (evt) => {
      let b;
      const val = evt.target.value;
      /* close any already open lists of autocompleted values */
      closeAllLists();
      if (!val) {
        return false;
      }
      currentFocus = -1;
      /* create a DIV element that will contain the items (values): */
      const paddingLeft = $(inp).parent().css('paddingLeft');
      const a = document.createElement('DIV');
      a.setAttribute('id', `${inp.id}autocomplete-list`);
      a.setAttribute('class', 'autocomplete-items');
      a.style.left = paddingLeft;
      /* append the DIV element as a child of the autocomplete container: */
      inp.parentNode.appendChild(a);
      /* for each item in the array... */
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i].substr(0, val.length).toUpperCase() === val.toUpperCase()) {
          b = document.createElement('DIV');
          b.innerHTML = `<strong>${arr[i].substr(0, val.length)}</strong>`;
          b.innerHTML += arr[i].substr(val.length);
          b.innerHTML += `<input type='hidden' value="${arr[i]}">`;
          b.addEventListener('click', (e) => {
            inp.value = e.target.getElementsByTagName('input')[0]
              ? e.target.getElementsByTagName('input')[0].value
              : e.target.parentElement.getElementsByTagName('input')[0].value;
            closeAllLists();
          });
          a.appendChild(b);
        }
      }
      return true;
    });
    /* execute a function presses a key on the keyboard: */
    inp.addEventListener('keydown', (e) => {
      let x = document.getElementById(`${inp.id}autocomplete-list`);
      if (x) x = x.getElementsByTagName('div');
      if (e.keyCode === 40) {
        /* If the arrow DOWN key is pressed,
         increase the currentFocus variable: */
        currentFocus += 1;
        /* and and make the current item more visible: */
        addActive(x);
      } else if (e.keyCode === 38) {
        // up
        /* If the arrow UP key is pressed,
         decrease the currentFocus variable: */
        currentFocus -= 1;
        /* and and make the current item more visible: */
        addActive(x);
      } else if (e.keyCode === 13) {
        /* If the ENTER key is pressed, prevent the form from being submitted */
        e.preventDefault();
        if (currentFocus > -1) {
          /* and simulate a click on the "active" item: */
          if (x) x[currentFocus].click();
        }
      }
    });
    /* execute a function when someone clicks in the document: */
    document.addEventListener('click', (e) => {
      closeAllLists(e.target);
    });
  });
};

export default init;
