import $ from 'jquery';

const multiSelect = (uniq) => {
  const $uniq = $(uniq);
  const $uniqEl = $(uniq)[0];
  const $content = $uniq.parents('.individual-accordion').find('.collapse')[0];
  const promise = new Promise((resolve) => {
    let uniqArrow;
    for (let i = $uniqEl.children.length - 1; i >= 0; i -= 1) {
      if ($uniqEl.children[i].tagName === 'svg') {
        uniqArrow = $uniqEl.children[i];
      }
    }
    resolve(uniqArrow);
  });
  promise.then((uniqArrow) => {
    if ($($content).hasClass('shows')) {
      $($content).slideUp().removeClass('shows');
      $(uniqArrow).removeClass('down').addClass('right');
      $($uniqEl).removeClass('active').removeAttr('data-ct-ignore').attr('aria-expanded', false);
    } else {
      $($content).slideDown().addClass('shows');
      $($uniqEl).addClass('active').attr({
        'data-ct-ignore': true,
        'aria-expanded': true,
      });
      $(uniqArrow).removeClass('right').addClass('down');
    }
  });
};
// eslint-disable-next-line no-unused-vars
const singleSelect = (uniq) => {
  const $uniq = $(uniq);
  const $uniqEl = $(uniq)[0];
  const $content = $uniq.parents('.individual-accordion').find('.collapse')[0];
  const promise = new Promise((resolve) => {
    let uniqArrow;
    for (let i = $uniqEl.children.length - 1; i >= 0; i -= 1) {
      if ($uniqEl.children[i].tagName === 'svg') {
        uniqArrow = $uniqEl.children[i];
      }
    }
    resolve(uniqArrow);
  });

  promise.then((uniqArrow) => {
    if ($($content).hasClass('shows')) {
      $($content).slideUp().removeClass('shows');
      $(uniqArrow).removeClass('down').addClass('right');
      $($uniqEl).removeClass('active').removeAttr('data-ct-ignore').attr('aria-expanded', false);
    } else {
      $($uniq.parents('.heavy-accordion-holder, .light-accordion-holder')[0])
        .find('.collapse.single-slct')
        .slideUp()
        .removeClass('shows');
      $($content).slideDown().addClass('shows');
      $($uniq.parents('.heavy-accordion-holder, .light-accordion-holder')[0])
        .find('.accordion-title-single')
        .removeClass('active')
        .attr('aria-expanded', false);
      $($uniq.parents('.heavy-accordion-holder, .light-accordion-holder')[0])
        .find('svg')
        .removeClass('down')
        .addClass('right');
      $($uniqEl).addClass('active').attr({
        'data-ct-ignore': true,
        'aria-expanded': true,
      });
      $(uniqArrow).removeClass('right').addClass('down');
    }
  });
};

const toggleAccordion = (uniq) => {
  const $uniq = $(uniq);
  const $uniqEl = $(uniq)[0];
  const $content = $uniq
    .parents('.individual-inline-accordion, .show-more-expander')
    .find('.collapse')[0];
  const controlText = $uniq
    .parents('.individual-inline-accordion, .show-more-expander')
    .find('.expand-control-text')[0];
  const promise = new Promise((resolve) => {
    let collapseText;
    let expandText;
    for (let i = controlText.attributes.length - 1; i >= 0; i -= 1) {
      if (controlText.attributes[i].name === 'data-accordion-collapse-text') {
        collapseText = controlText.attributes[i].value;
      } else if (controlText.attributes[i].name === 'data-accordion-expand-text') {
        expandText = controlText.attributes[i].value;
      }
    }
    const attrs = {
      collapseText,
      expandText,
    };
    resolve(attrs);
  });

  promise
    .then((attrs) => {
      let uniqArrow;
      let uniqCtrlText;
      for (let i = $uniqEl.children.length - 1; i >= 0; i -= 1) {
        if ($uniqEl.children[i].tagName === 'svg') {
          uniqArrow = $uniqEl.children[i];
        } else if ($uniqEl.children[i].classList.contains('expand-control-text')) {
          uniqCtrlText = $uniqEl.children[i];
        }
      }
      const allItems = { ...attrs, uniqArrow, uniqCtrlText };
      return allItems;
    })
    .then((allItems) => {
      if ($($content).hasClass('shows')) {
        $($content).slideUp().removeClass('shows');
        $(allItems.uniqArrow).removeClass('down up').addClass('right');
        $(allItems.uniqCtrlText)
          .text(allItems.expandText)
          .removeAttr('data-ct-ignore')
          .attr('aria-expanded', false);
      } else {
        $($content).slideDown().addClass('shows');
        if ($(allItems.uniqArrow).parents('.show-more-control-inline')[0]) {
          $(allItems.uniqArrow).removeClass('right').addClass('up');
        } else {
          $(allItems.uniqArrow).removeClass('right').addClass('down');
        }
        $(allItems.uniqCtrlText).text(allItems.collapseText).attr({
          'data-ct-ignore': true,
          'aria-expanded': true,
        });
      }
    });
};

export const initMultiSelect = () => {
  $(document).on('click', '.accordion-title', (e) => {
    e.preventDefault();
    multiSelect(e.currentTarget);
  });
};

export const initSingleSelect = () => {
  $(document).on('click', '.accordion-title-single', (e) => {
    if ($(e.currentTarget).find('.mobile-childless-spacing').length <= 0) {
      e.preventDefault();
      // Changed to multiSelect() so not to edit XSL and redeploy pages
      multiSelect(e.currentTarget);
      // singleSelect(e.currentTarget);
    }
  });
};

export const initInlineSelect = () => {
  $(document).on('click', '.accordion-control-inline, .show-more-control-inline', (e) => {
    e.preventDefault();
    toggleAccordion(e.currentTarget);
  });
};

export const initExpDefault = () => {
  const expDef = $('.exp-default').children('button');
  expDef.each((index, el) => multiSelect(el));
};

export const initAllSelect = () => {
  $(document).on('click', '.accordion-controls button', (e) => {
    e.preventDefault();
    const $this = $(e.currentTarget);
    const $accordionBlock = $this
      .parents('.heavy-accordion-holder, .light-accordion-holder')
      .first()
      .find('button[data-ct-header="true"]');
    let $uniqRows;
    if ($this.hasClass('expand-all')) {
      $uniqRows = $($accordionBlock).not('.active');
    } else {
      $uniqRows = $($accordionBlock).filter('.active');
    }
    $uniqRows.each((index, el) => multiSelect(el));
  });
};

export const initFade = () => {
  $('[data-toggle="collapse-custom"]').on('click', (e) => {
    e.preventDefault();
    const target = $(e.currentTarget).attr('data-target');
    let txt;
    if ($(target).hasClass('show')) {
      txt = $(e.currentTarget).attr('data-accordion-expand-text');
      $(target).animate(
        { height: parseInt($(e.currentTarget).attr('data-collapse-height'), 10) },
        200
      );
    } else {
      txt = $(e.currentTarget).attr('data-accordion-collapse-text');
      $(target).animate({ height: $(target).prop('scrollHeight') }, 200);
    }
    $(e.currentTarget).html(
      $(e.currentTarget).html().replace(e.currentTarget.innerText.trim(), txt)
    );
    $(target).toggleClass('show');
    $(e.currentTarget).find('svg').toggleClass('angle-down angle-up');
  });
};

export default () => {
  initMultiSelect();
  initSingleSelect();
  initInlineSelect();
  initExpDefault();
  initAllSelect();
  initFade();

  // eStudio Only
  const isEstudio = $('.iw-fullscreen-edit');
  if (isEstudio.length) $('[class*="accordion-title"]:visible').each((i, accordion) => multiSelect(accordion)); // eslint-disable-line
  // eStudio Only
};
