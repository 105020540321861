import $ from 'jquery';
import Autocomplete from '../autocomplete';
import countries from '../../../../docs/_data/countries.json';

class Form {
  static autocomplete(name, arr = []) {
    const element = $(`[data-js-autocomplete="${name}"]`);
    if (element.length) {
      if (name === 'country') {
        Autocomplete(`[data-js-autocomplete="${name}"]`, countries);
      } else {
        Autocomplete(`[data-js-autocomplete="${name}"]`, arr);
      }
    }
  }
}

export default Form;
