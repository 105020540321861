import $ from 'jquery';

export default () => {
  const linkElem = '[data-link-onclick="country-guide"]';
  const selectElem = '[data-select-onchange="country-guide"]';

  $(document.body).on('click', linkElem, (evt) => {
    const value = $(evt.currentTarget).attr('href');
    $(selectElem).val(value);
  });

  $(document.body).on('change', selectElem, (evt) => {
    const value = $(evt.currentTarget).val();
    window.location.hash = value;
  });

  if (window.location.hash.indexOf('country-guide') > -1) {
    $(`a[href="${window.location.hash}"]`).click();
  }
};
