export default (currentScript) => {
  let scriptPath;
  try {
    scriptPath = new URL(currentScript).pathname;
  } catch (e) {
    const temp = document.createElement('a');
    temp.href = currentScript;
    scriptPath = temp.pathname;
  }
  return scriptPath.substr(0, scriptPath.lastIndexOf('/'));
};
