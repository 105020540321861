import 'core-js/features/array/every';
import 'core-js/features/array/find';
import 'core-js/features/array/from';
import 'core-js/features/array/includes';
import 'core-js/features/map';
import 'core-js/features/object/assign';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/promise';
import 'core-js/features/set';
import 'core-js/features/string/ends-with';
import 'core-js/features/string/includes';
import 'core-js/features/string/starts-with';
import 'core-js/features/symbol';
import 'svgxuse';
import 'whatwg-fetch';

if (!window.location.origin) {
  const { protocol, hostname, port } = window.location;
  window.location.origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
}

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
