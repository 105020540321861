import $ from 'jquery';
import mediator from '../../utils/mediator';

const shareButtons = $('.share-buttons');
const isFixed = () => shareButtons.hasClass('share-buttons-fixed');
let updating;
let lastWinWidth;
let bottomOffset;
let shareButtonsOffset;
let startElemOffset;

// eslint-disable-next-line consistent-return
const handleScroll = () => {
  const scrollTop = Math.max(
    window.pageYOffset,
    document.documentElement.scrollTop,
    document.body.scrollTop
  );
  const scrollOffset = scrollTop + shareButtons.height();
  const $scrollStartElem = $(shareButtons.attr('data-scroll-target-start'));
  const $scrollEndElem = $(shareButtons.attr('data-scroll-target-end'));
  bottomOffset =
    $scrollEndElem.length > 0
      ? $scrollEndElem.offset().top
      : $scrollStartElem.offset().top + $scrollStartElem.height();
  startElemOffset = $scrollStartElem.offset().top;

  if (lastWinWidth < 1300) {
    shareButtons.css({ transform: '' });
    shareButtons.removeClass('share-buttons-fixed');
    updating = false;
    return false;
  }

  if (scrollTop < startElemOffset) {
    shareButtons.css({ transform: 'translateY(0px)' });
    shareButtons.removeClass('share-buttons-fixed');
  } else if (scrollOffset > bottomOffset && isFixed()) {
    shareButtonsOffset = scrollTop - startElemOffset;
    shareButtons.css({ transform: `translateY(${shareButtonsOffset}px` });
    shareButtons.removeClass('share-buttons-fixed');
  } else if (scrollOffset < bottomOffset && !isFixed()) {
    shareButtons.css({ transform: `translateY(0px)` });
    shareButtons.addClass('share-buttons-fixed');
  }

  updating = false;
};

/**
 * Callback for our scroll event - just
 * keeps track of the last scroll value
 * Checks if not already updating
 * rAF called with scroll handler function.
 */
const onScroll = () => {
  lastWinWidth = window.innerWidth;

  if (!updating) {
    requestAnimationFrame(handleScroll);
    updating = true;
  }
};

/**
 * Set initial positioning.
 */
const initPositioning = () => {
  if (window.innerWidth >= 1300) {
    shareButtons.css({ transform: 'translateY(0px)' });
    onScroll();
  }
};

export default () => {
  const $scrollStartElem = $(shareButtons.attr('data-scroll-target-start'));
  const $scrollEndElem = $(shareButtons.attr('data-scroll-target-end'));
  updating = false;
  lastWinWidth = 0;
  bottomOffset =
    $scrollEndElem.length > 0
      ? $scrollEndElem.offset().top
      : $scrollStartElem.offset().top + $scrollStartElem.height();
  shareButtonsOffset = bottomOffset;
  startElemOffset = $scrollStartElem.offset().top;

  initPositioning();
  window.addEventListener('scroll', onScroll);
  mediator.on('window:throttledResize', onScroll);
};
