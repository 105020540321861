import $ from 'jquery';

export default () => {
  const prevValue = null;
  $(document).on('blur', '[data-js-autocomplete="country"]', (evt) => {
    evt.preventDefault();
    setTimeout(() => {
      if (prevValue !== evt.target.value) {
        const DCRs = JSON.parse($('#dcr-json-data').html());
        if (DCRs[evt.target.value]) window.location.assign(DCRs[evt.target.value]);
      }
    }, 200);
  });
};
