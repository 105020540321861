// Trying to avoid downloading 3rd party social scripts and use simple social shraing links instead
// https://jonsuh.com/blog/social-share-links/
import $ from 'jquery';
import { initBlankLinks } from '../../links';
import { encodeString } from '../../utils/url';
import initScrollHandler from './scroll-handler';

// TODO: Move to some polyfill location
// eslint-disable-next-line no-restricted-globals
if (!location.origin) {
  // eslint-disable-next-line no-restricted-globals
  location.origin = `${location.protocol}//${location.host}`;
}

// eslint-disable-next-line no-restricted-globals
const defaultLogoURL = `${location.origin}/static/images/cigna-logo.png`;

// defaultTarget can be _self, _blank, or popup
const services = {
  email: {
    defaultTarget: '_self',
    urlTemplate: ({ body, text }) => `mailto:?subject=${text}&body=${body}`,
  },
  facebook: {
    defaultTarget: 'popup',
    urlTemplate: ({ url }) => `https://facebook.com/sharer/sharer.php?u=${url}`,
  },
  twitter: {
    defaultTarget: 'popup',
    // eslint-disable-next-line max-len
    urlTemplate: ({ url, text }) => `https://twitter.com/share?url=${url}&text=${text}`, // &via={via}&hashtags={hashtags}
  },
  linkedin: {
    defaultTarget: '_blank',
    // eslint-disable-next-line max-len
    urlTemplate: ({ url }) =>
      `https://www.linkedin.com/shareArticle?mini=true&url=${url}&source=cigna.com`,
  },
  print: {
    defaultTarget: '_self',
  },
};

const getText = () => $.trim($('h1:first').text() || $('title').text());

const getBody = () => {
  // eslint-disable-next-line no-restricted-globals
  const parts = [$.trim($('meta[name=description]').attr('content')), location.href];
  return $.grep(parts, Boolean).join('\n\n');
};

const popup = (url, title, width, height) => {
  // eslint-disable-next-line max-len
  // http://stackoverflow.com/questions/1248081/get-the-browser-viewport-dimensions-with-javascript/36132694#36132694
  // eslint-disable-next-line no-restricted-globals
  const scrLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
  // eslint-disable-next-line no-restricted-globals
  const scrTop = window.screenTop !== undefined ? window.screenTop : screen.top;

  const scrWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const scrHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

  const left = scrWidth / 2 - width / 2 + scrLeft;
  const top = scrHeight / 3 - height / 3 + scrTop;

  const newWindow = window.open(
    url,
    title,
    `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`
  );
  if (newWindow && newWindow.focus) {
    newWindow.focus();
  }
};

// eslint-disable-next-line consistent-return
const clickHandler = (evt) => {
  const link = $(evt.currentTarget);
  const action = link.data('action');

  if (action) {
    evt.preventDefault();
    if (action === 'popup') {
      popup(link.attr('href'), $.trim(link.text()), 580, 470);
    } else if (action === 'print') {
      window.print();
    }
    return false;
  }
};

export const initClickHandlers = (logoURL = defaultLogoURL) => {
  const details = {
    media: encodeString(logoURL),
    // eslint-disable-next-line no-restricted-globals
    url: encodeString(location.href),
    text: encodeString(getText()),
    body: encodeString(getBody()),
  };

  // eslint-disable-next-line no-unused-vars
  $('[data-social]').each((i, el) => {
    const link = $(el);
    const serviceName = link.data('social');
    const service = services[serviceName] || '';

    if (service) {
      if (serviceName === 'print') {
        link.attr('data-action', 'print');
      } else if (service.urlTemplate) {
        link.attr('href', service.urlTemplate(details));
        const target = link.attr('target') || service.defaultTarget;
        if (target === 'popup') {
          link.attr('data-action', 'popup');
        } else {
          link.attr('target', target);
        }
        initBlankLinks();
      }
    }
  });

  $('[data-social]')
    .on('click', clickHandler)
    .on('keypress', (evt) => {
      if (evt.keyCode === 32 || evt.keyCode === 13) {
        evt.preventDefault();
        clickHandler(evt);
      }
    });
};

export default (logoURL = defaultLogoURL) => {
  initClickHandlers(logoURL);
  if (document.querySelector('[data-scroll-target-start]')) {
    initScrollHandler();
  }
};
