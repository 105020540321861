import $ from 'jquery';

const subNavSticky = () => {
  // Get the sub nav
  const subNav = document.getElementsByClassName('subnav-anchorlink')[0];

  // Get the offset position of the sub nav
  const sticky = subNav.offsetTop;

  // Add the sticky class to the sub nav when you reach its scroll position.
  // Remove "sticky" when you leave the scroll position
  const onScroll = () => {
    if (window.pageYOffset > sticky) {
      subNav.classList.add('sticky');
    } else {
      subNav.classList.remove('sticky');
    }
  };

  window.onscroll = () => onScroll();
};

export default () => {
  if ($('[data-sticky="true"]').length && $('.subnav-anchorlink').is(':visible')) {
    subNavSticky();
    $('body').scrollspy({ target: '.subnav-anchorlink' });
  }
};
