import config from '../config';
import renderTemplate from '../utils/template';
import svgTemplate from '../views/svg.tpl.html';

const componentsSvg = `${config.svgPath}/component-sprite.svg`;

export default ($form) => {
  $form.parsley({
    successClass: 'input-success',
    errorClass: 'input-error',
    classHandler: (el) => el.$element.closest('.input-holder'),
    errorsWrapper: '<div class="error-feedback"></div>',
    errorTemplate: '<p class="error-message"></p>',
  });

  $form.parsley().on('field:error', (fieldInstance) => {
    const svg = renderTemplate(svgTemplate, {
      cls: 'icn m-icn feedback-icn',
      svgSpritePath: componentsSvg,
      icon: 'info-circle',
    });
    const feedbackBox = fieldInstance.$element.next('.error-feedback');
    if (!feedbackBox.find('svg').length) {
      feedbackBox.prepend(svg);
    }
  });

  /*
  $form.parsley().on('field:success', (fieldInstance) => {
    const svg = renderTemplate(svgTemplate, {
      cls: 'icn ml-icn validation-icn',
      svgSpritePath: componentsSvg,
      icon: 'check-circle',
    });
    if (!fieldInstance.$element.next('svg.validation-icn').length) {
      fieldInstance.$element.after(svg);
    }
  });
  */
};
