import $ from 'jquery';
import { getParams, buildParams } from '../utils/url';

const currentParams = getParams(window.location.search);
const onLanguageSelect = (e) => {
  e.preventDefault();
  const language = e.target.getAttribute('href').substring(1, 3);
  const pathName = window.location.pathname.substr(3);
  const params = buildParams(currentParams);
  const newUrl = `${window.location.origin}/${language}${pathName}?${params}`;
  window.location.href = newUrl;
};

export default () => {
  if ($('[data-language-switcher]').length) {
    $(document).on('click', '[data-language-switcher] a', onLanguageSelect);
  }
};
