/* eslint-disable prettier/prettier */
import $ from 'jquery';
import 'parsleyjs';
import { getParams, getLanguageSection } from './utils/url';
import { capitalizeEveryWord } from './utils/text';

const currentLocation = window.location.pathname;
const previousLocation = document.referrer;
const pageCategory = $('meta[name="pageCategory"]').attr('content');
const mainGTMObj = {
  userId: null,
  pageCategory,
  pageSubCategory: null,
  webCountry: 'UK',
  websiteLanguage: getLanguageSection(currentLocation),
};

const pushDataToGTM = (args) => {
  window.dataLayer = window.dataLayer || [];
  const tempData = Object.assign(args, mainGTMObj);
  window.dataLayer.push(tempData);
};
/* eslint-disable no-unused-vars */
const getPageNameFromLink = (el, type) =>capitalizeEveryWord(el.attr('href')
      .split('/')
      .filter(Boolean)
      .pop()
      .split(/(?:-|{type})+/)
      .join(' ')
      .trim()
  );

const loadGoogleAnalytics = () => {
  window.dataLayer = window.dataLayer || [];
  /* eslint-disable */
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-W2BXR25');
};

export default () => {
    $(window).on('load', () => {
    if (currentLocation === '/error') {
      pushDataToGTM({ event: 'pageError', errorMessage: 'Error 404 - Page not found'});
      loadGoogleAnalytics();
    } else if (currentLocation === '/search') {
      var waitForSearchResults = setInterval(() => {
        if ($('#main-template').length) {
          clearInterval(waitForSearchResults);
          const searchQuery = getParams(window.location.search).query;
          const resultsHolder = $('[data-total-search-results]').attr('data-total-search-results');
          const siteSearchResults = resultsHolder !== undefined ? resultsHolder : '0';
          pushDataToGTM({
            cat55: 'Site Search',
            kw55: searchQuery,
            siteSearchResults
          });
          loadGoogleAnalytics();
        }
      }, 100);
    } else {
      const countLanguageChange = getLanguageSection(previousLocation) === getLanguageSection(currentLocation) ? 0 : 1;
      pushDataToGTM({ countLanguageChange });
      loadGoogleAnalytics();
    }
  });
  // All click events
  $(document)
    // All blogs and articles
    .on('click', '.post [href*="/article/"], .post [href*="/blog/"]', (el) => {
      const $this = $(el.currentTarget);
      const articleName = getPageNameFromLink($this, '.html');
      pushDataToGTM({ event: 'articleClick', articleName });
    })
    // All internal PDF links
    .on('click', '[href^="/"][href$=".pdf"]', (el) => {
      const $this = $(el.currentTarget);
      const documentName = getPageNameFromLink($this, '.pdf');
      pushDataToGTM({ event: 'download', documentName });
    })
    // All external links, excluding 'quote' links
    .on('click', 'a.link-external:not([href*="quote"])', (el) => {
      const $this = $(el.currentTarget);
      const externalSiteName = $this.attr('href');
      pushDataToGTM({ event: 'externalSiteLink', externalSiteName });
    })
    // All external 'quote' links
    .on('click', 'a.link-external[href*="quote"]', (el) => {
      const $this = $(el.currentTarget);
      const planCardContainer = $this.parents('.plan-cards');
      const productName = planCardContainer.length ? planCardContainer.find('.plan-cards__plan-title').text().trim() : null;
      pushDataToGTM({ event: 'getaQuote', productName });
    })
    // All form submits, including newsletter subscriptions
    .on('submit', 'form:not(.elastic-search)', (el) => {
      const $this = $(el.currentTarget);
      const formName = $this.attr('name');
      const eventName = $this.attr('id') === 'subscribeToNewsletterForm' ? 'newsletterSubscription' : 'contactRequest';
      if ($this.parsley().isValid()) {
        pushDataToGTM({ event: eventName, formName });
      } else {
        pushDataToGTM({ event: 'errorInForm', formError: 'Field required', formName });
      }
    })
    // All social media links
    .on('mousedown', '.social-media-group a, .share-buttons a', (el) => {
      const $this = $(el.currentTarget);
      const socialNetwork = capitalizeEveryWord($this.attr('data-social'));
      const socialAction = $this.attr('data-social-footer') ? 'Follow' : 'Share';
      const socialEvent = $this.attr('data-social-footer') ? 'socialFollow' : 'socialShare';
      pushDataToGTM({ event: socialEvent, socialAction, socialNetwork });
    })
    // Plan card button that expands more/less information
    .on('click', '.plan-cards [data-accordion-collapse-text]', (el) => {
      const $this = $(el.currentTarget);
      const planCardContainer = $this.parents('.plan-cards');
      const productName = planCardContainer.find('.plan-cards__plan-title').text().trim();
      const tabName = planCardContainer.data('plan-cards-header').length ? planCardContainer.data('plan-cards-header') : null;
      pushDataToGTM({ event: 'informationTabClick', tabName, productName });
    })
    // Anytime an accordion is collapsed/expanded
    .on('click', 'button.accordion-title-single:not(".nav-accordion-title")', (el) => {
      const $this = $(el.currentTarget);
      const accordionHeading = $this.text().trim();
      pushDataToGTM({ event: 'toggleClick', accordionHeading });
    });
    // Header/Footer link clicks
    $('.header-meganav a, footer a:not(.social-media-link)').on('click', (el) => {
      const $this = $(el.currentTarget);
      const locationClicked = $this.parents('footer').length ? 'Footer' : 'Top-Menu';
      const countMenuClick = 1;
      const navigationLinkClicked = $this.hasClass('navbar-brand') ? 'Home' : $this.text().trim();
      pushDataToGTM({ event: 'menuNavigationClick', countMenuClick, locationClicked, navigationLinkClicked });
    });
};
