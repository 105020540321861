import $ from 'jquery';
import mediator from '../utils/mediator';

const quoteAlertBg = (quoteAlert) => {
  const quoteHeight = quoteAlert[0].offsetHeight;
  const quoteBackground = $('.sticky-retrieve-quote-background');

  quoteBackground.css('height', `${quoteHeight}px`);
};

const quoteAlertSticky = () => {
  const screenSize = window.cigna.screen.breakpoint;
  const quoteAlert = $('.sticky-retrieve-quote');

  const onScroll = () => {
    const sticky = quoteAlert[0].offsetTop;
    if (window.pageYOffset > sticky) {
      quoteAlert.addClass('sticky');
    } else {
      quoteAlert.removeClass('sticky');
    }
  };

  window.onscroll = () => {
    if (['lg', 'xl'].includes(screenSize)) onScroll();
  };
  quoteAlert.toggleClass('sticky', ['xs', 'sm', 'md'].includes(screenSize));
  quoteAlertBg(quoteAlert);
};

export default () => {
  if ($('.sticky-retrieve-quote').length) {
    quoteAlertSticky();
    $('body').scrollspy({ target: '.sticky-retrieve-quote' });

    mediator.on('window:throttledResize', () => {
      quoteAlertSticky();
    });
  }
};
