import { template } from 'lodash-es';

/*
 * Renders a template using lodash `_.template()`, note that importing html requires
 * a `raw-loader` setup like this:
 *
 * {
 *   test: /\.tpl.html$/,
 *   loader: 'raw-loader'
 * }
 *
 * Example:
 * import renderTemplate from 'lib/template';
 * import someTemplate from 'views/someTemplate.tpl.html';
 *
 * const html = renderTemplate(someTemplate, context);
 */
export default (tpl, params, options = {}) => {
  const compiled = template(tpl, options);
  return compiled(params);
};
