import mediator from '../mediator';

const setShadow = (nodes) => {
  const IMAGES = nodes;
  const SHADOW_MARGIN = 32 * 2;
  [].forEach.call(IMAGES, (el) => {
    const SHADOW = el.nextElementSibling;
    const IMG_WIDTH = el.clientWidth;
    SHADOW.style.width = `${IMG_WIDTH - SHADOW_MARGIN}px`;
    SHADOW.style.opacity = 1;
  });
};

export default () => {
  const IMAGES = document.querySelectorAll('.variant-block-section-image');
  if (IMAGES.length) {
    setShadow(IMAGES);
    mediator.on('window:throttledResize', () => {
      [].forEach.call(IMAGES, (el) => {
        const SHADOW = el.nextElementSibling;
        SHADOW.style.opacity = 0;
      });
      setShadow(IMAGES);
    });
  }
};
