const ENDPOINT = {
  dev: 'https://d-gsg.dev.digitaledge.cigna.com/public/cigna/search',
  qa: 'https://a-gsg.test.digitaledge.cigna.com/public/cigna/search',
  rel1: 'https://a-gsg.test.digitaledge.cigna.com/public/cigna/search',
  rel2: 'https://a-gsg.test.digitaledge.cigna.com/public/cigna/search',
  pvs: 'https://v-gsg.test.digitaledge.cigna.com/public/cigna/search',
  prod: 'https://p-gsg.digitaledge.cigna.com/public/cigna/search',
};

export default ENDPOINT;
