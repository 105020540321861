// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import $ from 'jquery';

const toggle = (event) => {
  const element = $(event.currentTarget);
  $('.currency-switcher__toggle').removeClass('currency-switcher__active');
  element.addClass('currency-switcher__active');

  const currencyFormat = element.attr('data-currency-format');

  $('.currency-amount').each((i, el) => {
    const currencyAmount = $(el);
    currencyAmount.text(currencyAmount.attr(`data-amount-${currencyFormat}`));
  });
};

const productCardIsInView = (el) => {
  const scroll = window.scrollY || window.pageYOffset;
  const boundsTop = el.getBoundingClientRect().top + scroll;

  const viewport = {
    top: scroll,
    bottom: scroll + window.innerHeight,
  };

  const bounds = {
    top: boundsTop,
    bottom: boundsTop + el.clientHeight,
  };

  return (
    (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
    (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
  );
};

const isCurrencyAmountInView = () => {
  const elements = document.querySelectorAll('.currency-amount');
  let i = 0;
  while (i < elements.length) {
    if (
      productCardIsInView(elements[i]) &&
      Object.keys($('.currency-amount')[i].dataset).length > 1
    ) {
      $('.currency-switcher').show();
      $(document).on('click', '.currency-switcher__toggle', toggle);
      break;
    } else {
      $('.currency-switcher').hide();
    }
    i += 1;
  }
};

export default () => {
  if ($('.currency-switcher').length && $('.currency-amount').length) {
    isCurrencyAmountInView();

    $(document).on('scroll', () => {
      isCurrencyAmountInView();
    });
  }
};
