import $ from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';

const openDropdown = (evt) => {
  const navItem = evt.currentTarget;
  const dropDown = $(navItem).siblings('.dropdown-menu');
  if ($(dropDown).is(':hidden')) {
    $(navItem).dropdown('toggle');
  }
};

let hoverTimeout;
const delay = $('.header-meganav').attr('data-delay');
const beginNavDropdown = (evt) => {
  hoverTimeout = setTimeout(() => {
    openDropdown(evt);
  }, delay);
};

export default () => {
  $('.main-navigation .nav-link[data-toggle="dropdown"]')
    .focus((evt, esc) => {
      if (!esc) beginNavDropdown(evt);
    })
    .mouseenter((evt) => {
      beginNavDropdown(evt);
    })
    .focusout(() => {
      clearTimeout(hoverTimeout);
    })
    .mouseout(() => {
      clearTimeout(hoverTimeout);
    })
    .click((evt) => {
      const element = evt.currentTarget;
      window.location.assign(encodeURI($(element).attr('href')));
    });

  $('.inner-menu-row').click((evt) => {
    evt.stopPropagation();
  });

  $('[data-toggle="tab"]').click((evt) => {
    const tab = evt.currentTarget;
    $(tab).tab('show');
  });

  $('.inner-menu .btn-close').click((evt) => {
    const close = evt.currentTarget;
    $(close).parents('.dropdown-menu').dropdown('hide');
    $(close)
      .parents('[data-nav-section]')
      .find($('[id*="-level-one-link"]'))
      .trigger('focus', jQuery.Event('focus'))
      .addClass('focus-visible');
  });
};
