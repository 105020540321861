import { is } from './type';

// Turns foo=bar&fizz=buzz to {foo: 'bar', fizz: 'buzz'}
export const getParams = (search) =>
  (search || window.location.search)
    .replace(/^\?/, '')
    .split('&')
    .filter(Boolean)
    .map((param) => (param.includes('=') ? param.split('=') : [param, true]))
    .reduce((result, input) => {
      const [key, val] = input;
      return Object.assign(result, { [key]: val === true ? val : decodeURIComponent(val) });
    }, {});

// from https://www.rrssb.ml
export const encodeString = (value) => {
  // Recursively decode string first to ensure we aren't double encoding.
  let text = value;
  if (text) {
    if (is(String, text) && text.match(/%[0-9a-f]{2}/i)) {
      text = decodeURIComponent(text);
      encodeString(text);
    } else {
      return encodeURIComponent(text);
    }
  }
  return false;
};

export const buildParams = (query) =>
  Object.keys(query)
    .map((param) => {
      const value = query[param];
      const queryValue = is(Array, value)
        ? value.map((v) => encodeString(v)).join(',')
        : encodeString(value);
      return `${param}=${queryValue}`;
    })
    .join('&');

export const updateQueryString = (newParams, url) => {
  const updateUrl = url || window.location;
  const searchParams = updateUrl.search ? getParams(updateUrl.search) : {};
  const params = { ...searchParams, newParams };
  return buildParams(params);
};

export const getLanguageSection = (path) => {
  const defaultLanguage = 'en';

  if (path === '/') {
    return defaultLanguage;
  }
  const regionLanguageRgx = /^\/([a-z]{2})\/(?:([a-z]{2})\/)*(?:([a-z-]+)\/)*/i;
  const valid = path.match(regionLanguageRgx);

  if (valid) {
    while (valid) {
      const [, language] = valid;
      return language;
    }
  }

  return defaultLanguage;
};
