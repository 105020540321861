import $ from 'jquery';
import mediator from '../utils/mediator';

const fixedColumn = (table) => {
  const wrapperDiv = $(table).closest('div');
  wrapperDiv.removeAttr('style').find('tr, .col-fixed').removeAttr('style');
  const fixedWidth = $(table).find('tbody .col-fixed').outerWidth();

  $(table)
    .find('tr')
    .each((index, el) => {
      const heightObj = {
        height: $(el).innerHeight(),
      };
      $(el).css(heightObj).find('.col-fixed').css(heightObj);
    });

  $(table)
    .find('.col-fixed')
    .css({
      position: 'absolute',
      'margin-left': `-${fixedWidth}px`,
      width: fixedWidth,
    });
  wrapperDiv.css('margin-left', `${fixedWidth}px`);
};

const configureFixedTable = () => {
  $('.table-column-fixed table').each((index, el) => {
    fixedColumn($(el));
  });
};

export default () => {
  configureFixedTable();
  mediator.on('window:throttledResize', configureFixedTable);
};
