import { debounce } from 'lodash-es';
import { updateConfig } from './config';
import mediator from './utils/mediator';
import getScreenProperties from './utils/screen';

export const addScrollHandler = () => {
  // Adds a global window:throttledScroll event to mediator, which debounces events
  // until the user has stopped scrolling the window for a reasonable amount of time.
  const onScroll = (evt) => {
    mediator.emitEvent('window:throttledScroll', [evt]);
  };

  window.addEventListener('scroll', debounce(onScroll, 200), { passive: true });
};

export const addResizeHandler = () => {
  const updateScreenProperties = () => updateConfig({ screen: getScreenProperties() });

  // Adds a global window:throttledResize event to mediator, which debounces events
  // until the user has stopped resizing the window for a reasonable amount of time.
  // Also updates detects some screen properties and updates the event object with those.
  const onResize = (evt) => {
    const { screen } = updateScreenProperties();
    /* eslint-disable-next-line */
    evt.screen = screen;
    mediator.emitEvent('window:throttledResize', [evt]);
  };

  window.addEventListener('resize', debounce(onResize, 200), { passive: true });
  window.addEventListener('orientationchange', debounce(onResize, 200), { passive: true });
  updateScreenProperties();
};

export const addErrorHandler = (addGA = true) => {
  if (addGA) {
    // eslint-disable-next-line
    window.addEventListener('error', window.__e=function f(e){f.q=f.q||[];f.q.push(e)});
  }

  window.addEventListener('unhandledrejection', (evt) => {
    evt.preventDefault();

    /* eslint-disable no-console */
    console.group('Unhandled promise rejection');
    console.log('Promise', evt.promise);
    console.log('Reason', evt.reason);
    console.groupEnd();
  });
};

export const addLoadHandler = () => {
  window.addEventListener('load', () => updateConfig({ loaded: true }));
};

export default () => {
  addLoadHandler();
  addScrollHandler();
  addResizeHandler();
  addErrorHandler();
};
