import $ from 'jquery';
import intlTelInput from 'intl-tel-input';
import getScriptPath from '../utils';
import { getParams } from '../utils/url';
import validator from './validator';

export const initAutoValidation = () => {
  if ($('[data-autovalidate]').length) {
    validator();
  }
};

export const initAutoPopulateFields = () => {
  // auto populate a form field with a value passed in a query param
  $('[data-autopopulate]').each((index, elem) => {
    const el = $(elem);
    let param = el.attr('data-autopopulate');
    if (!param || param === 'true' || param === '1') {
      param = 'source';
    }
    const value = getParams()[param] || '';
    el.val(value);
  });
};

export const intlTelephone = (scriptPath) => {
  if ($('input[type="tel"]').length) {
    const telInput = document.querySelector('input[type="tel"]');

    intlTelInput(telInput, {
      separateDialCode: true,
      utilsScript: [scriptPath, 'tel-utils.js'].filter(Boolean).join('/'),
    });
  }
};

const eloquaForm = () => {
  if ($('#requestACallbackForm').length || $('#emailUsForm').length) {
    $(window).on('pageshow', () => {
      if ($('select[name="existingCustomer"]').val() === 'No') {
        $('input[name="policyNumber"]').closest('.input-holder').toggleClass('d-none', true);
        $('[data-listen-element-option="Yes"]').hide();
        $('[data-listen-element-option="No"]').show();
        $('select[name="whereLiving"]').closest('.input-holder').toggleClass('d-none', false);
        $('select[name="whereLiving"]').prop('required', true);
      }
      $('[data-autovalidate]')[0].reset();
    });

    $('select[name="existingCustomer"]').on('change', (evt) => {
      const existingCustomer = $(evt.currentTarget).val();

      if (existingCustomer === 'Yes') {
        $('input[name="policyNumber"]').closest('.input-holder').toggleClass('d-none', false);
        $('[data-listen-element-option="Yes"]').show();
        $('[data-listen-element-option="No"]').hide();
        $('select[name="whereLiving"]').closest('.input-holder').toggleClass('d-none', true);
        $('select[name="whereLiving"]').removeAttr('required');
      } else {
        $('input[name="policyNumber"]').closest('.input-holder').toggleClass('d-none', true);
        $('[data-listen-element-option="Yes"]').hide();
        $('[data-listen-element-option="No"]').show();
        $('select[name="whereLiving"]').closest('.input-holder').toggleClass('d-none', false);
        $('select[name="whereLiving"]').prop('required', true);
      }
      $('select[name="helpDropdown"]').val(
        $(
          // eslint-disable-next-line
          `select[name="helpDropdown"] option[data-listen-element-option="${existingCustomer}"]:first`
        ).val()
      );
    });

    $(document).on('change', 'select[name="helpDropdown"]', (evt) => {
      const helpDropdown = $(evt.currentTarget).val();
      if (helpDropdown === 'New quote') {
        $('input[name="quoteRefNumber"]').closest('.input-holder').toggleClass('d-none', true);
        $('input[name="quoteRefNumber"]').val('');
        $('select[name="whereLiving"]').closest('.input-holder').toggleClass('d-none', false);
        $('select[name="whereLiving"]').prop('required', true);
      } else if (helpDropdown === 'Existing quote') {
        $('input[name="quoteRefNumber"]').closest('.input-holder').toggleClass('d-none', false);
        $('select[name="whereLiving"]').closest('.input-holder').toggleClass('d-none', true);
        $('select[name="whereLiving"]').removeAttr('required');
      } else {
        $('input[name="quoteRefNumber"]').closest('.input-holder').toggleClass('d-none', true);
        $('input[name="quoteRefNumber"]').val('');
        $('select[name="whereLiving"]').closest('.input-holder').toggleClass('d-none', true);
        $('select[name="whereLiving"]').removeAttr('required');
      }
    });
  }
};

export default () => {
  /* eslint-disable-next-line prettier/prettier */
  const currentScriptSrc = document.currentScript !== undefined ? document.currentScript.src : '/static/js'; // IE11 does not support currentScript
  const scriptPath = getScriptPath(currentScriptSrc);

  initAutoPopulateFields();
  initAutoValidation();
  intlTelephone(scriptPath);
  eloquaForm();
};
